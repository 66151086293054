<template>
  <PowerFulTable
    :columns="columns"
    :data="list"
    :options="options"
    class="list-view-table"
    ref="table"
    @handleCurrentChange="(row) => $emit('tower-change', row)"
  />
</template>

<script>
// eslint-disable-next-line import/no-unresolved, import/extensions
import PowerFulTable from '@/components/table/powerful-table.vue'
import { listViewHeaders } from './index-data'

export default {
  components: {
    PowerFulTable
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      options: {
        property: { highlightCurrentRow: true }
      },
      columns: listViewHeaders
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.list-view-table {
  ::v-deep .el-table__row .cell {
    height: 90px;
    line-height: 50px;
  }
}
</style>
